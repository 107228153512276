<template>
  <div>
    <div class="section">
      <div class="container c800" style="max-width: 800px">
        <br />

        <!-- h1 choose a name -->
        <h1 class="title is-3">Post a GPT</h1>
        <p>It will be featured on various sites, including gpts.reviews.</p>
        <div class="box">
          <b-field label=" URL " name="chatUrl" class="field">
            <div>
              <b-input
                v-model="chatUrl"
                name="chatUrl"
                type="text"
                placeholder="ex: https://chat.openai.com/..."
                message="Enter the URL of your GPT chatbot"
              ></b-input>
              <b-tooltip
                type="is-dark"
                position="is-right"
                multilined
                label="Enter a the ChatGPT URL of this GPT chatbot"
              >
                <i class="fal fa-question-circle" aria-hidden="true"></i>
              </b-tooltip>
            </div>
          </b-field>
        </div>

        <div class="signup form" style="max-width: 600px">
          <b-button type="is-dark" @click="submit" :posting="posting" :disabled="posting || !isUrlValid"
            >Continue</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
async function fetchAndParseUrl(url) {
  try {
    // Fetch the content
    const response = await fetch(url);
    const htmlContent = await response.text();

    console.log(htmlContent);

    // Parse the HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Extract elements - for example, getting all <p> tags
    const elements = doc.querySelectorAll("p");
    console.log(elements);
    // Process elements as needed
    // ...
  } catch (error) {
    console.error("Error fetching or parsing URL:", error);
    // Handle errors and provide user feedback
  }
}

export default {
  data() {
    return {
      posting: false,
      chatUrl: "",
      inviteId: "",
      invite: {},
    };
  },
  watch: {
    chatUrl(newUrl) {
      this.fetchContent(newUrl);
    },
  },
  computed: {
    isUrlValid() {
      return this.chatUrl.startsWith("https://chat.openai.co");
    },
  },
  methods: {
    async fetchContent() {
      if (!this.isUrlValid) {
        console.log("Invalid URL");
        return;
      }

      try {
        const response = await fetch(this.chatUrl);
        const text = await response.text();
        this.fetchedContent = text; // Save the fetched content
      } catch (error) {
        console.error("Error fetching URL:", error);
        // Handle errors and provide user feedback
      }
    },
    async submit() {
      const data = {
        chatUrl: this.chatUrl,
      };

      window.API.createGpt(data)
        .then((res) => {
          console.log(res);

          this.$router.push("/gpts/" + res.id + "/edit?editKey=" + res.editKey);
        })
        .catch((err) => {
          console.log(err);
          this.$buefy.toast.open({
            message: "Error: Ensure the URL is valid",
            type: "is-danger",
            position: "is-top",
            duration: 9000,
            queue: false,
          });
        });
    },
  },
};
</script>
